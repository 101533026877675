import { makeAutoObservable, runInAction } from 'mobx';
import {
  CartApiServiceService,
  CartDetailProduct,
  CartDetailResponseDto,
  ConfigResponseDto,
  ContactInputDto,
  CreateCartResponseDto,
  NewCartInputDto,
  ProductInputDto,
  SpecialRequestInputDto,
  Subscription,
  SubscriptionInputDto,
  Traveller,
} from '../../services/codegen-romulo';

export default class CartApiStore {
  createdCart?: CreateCartResponseDto;

  cartConfiguration?: ConfigResponseDto;

  cartInformation?: CartDetailResponseDto;

  flightInformation?: CartDetailProduct;

  travellersInformation?: Traveller[];

  cartIsExpired?: boolean;

  subscriptionsInformation?: Subscription[];

  availableProducts?: Record<string, any>;

  order?: any;

  constructor() {
    makeAutoObservable(this);
  }

  createCart = async (
    requestBody: NewCartInputDto
  ): Promise<CreateCartResponseDto> => {
    const response =
      await CartApiServiceService.postApiServicesAppCartApiServiceCreateCart({
        requestBody,
      }).catch((error) => error.response.data.error.message);
    if (response?.apiResponse?.cartId) {
      this.createdCart = response;
      this.cartIsExpired = false;
    } else {
      this.cartIsExpired = true;
    }

    return response;
  };

  getCartConfig = async (cartId: string, uuid: string): Promise<void> => {
    CartApiServiceService.getApiServicesAppCartApiServiceGetCartConfig({
      cartId,
      requestUuid: uuid,
    })
      .then((response) => {
        runInAction(() => {
          if (response.contact) {
            this.cartConfiguration = response;
            this.cartIsExpired = false;
          } else {
            this.cartIsExpired = true;
          }
        });
      })
      .catch((e) => {
        this.cartIsExpired = true;
      });
  };

  addContactToCart = async (
    cartId: string,
    uuid: string,
    requestBody: ContactInputDto
  ): Promise<boolean> => {
    const response =
      await CartApiServiceService.postApiServicesAppCartApiServiceAddContactToCart(
        { cartId, requestUuid: uuid, requestBody }
      ).catch((error) => error.response.data.error.message);

    return response;
  };

  addTravellerInfoToCart = async (
    cartId: string,
    uuid: string,
    requestBody: Traveller[]
  ): Promise<boolean> => {
    const response =
      await CartApiServiceService.postApiServicesAppCartApiServiceAddTravellerToCart(
        { cartId, requestUuid: uuid, requestBody }
      ).catch((error) => error.response.data.error.message);

    return response;
  };

  getCartInfo = async (cartId: string, uuid: string): Promise<void> => {
    await CartApiServiceService.postApiServicesAppCartApiServiceRetrieveCart({
      cartId,
      requestUuid: uuid,
    })
      .then((response) => {
        runInAction(() => {
          if (response.contact) {
            this.cartInformation = response;
            this.cartIsExpired = false;
            const flightProduct = response.products.find(
              (product) => product.type === 'FLIGHT'
            );
            this.flightInformation = flightProduct;
          } else {
            this.cartIsExpired = true;
          }
        });
      })
      .catch((e) => {
        this.cartIsExpired = true;
      });
  };

  getTravellersInfo = async (cartId: string, uuid: string): Promise<void> => {
    const response =
      await CartApiServiceService.postApiServicesAppCartApiServiceRetrieveTravellersByCartId(
        { cartId, requestUuid: uuid }
      );

    runInAction(() => {
      this.travellersInformation = response;
    });
  };

  addSubscriptionsToCart = async (
    cartId: string,
    uuid: string,
    requestBody: SubscriptionInputDto[]
  ): Promise<boolean> => {
    const response =
      await CartApiServiceService.postApiServicesAppCartApiServiceAddSubscriptions(
        { cartId, requestUuid: uuid, requestBody }
      ).catch((error) => error.response.data.error.message);

    return response;
  };

  getSubscriptions = async (cartId: string, uuid: string): Promise<void> => {
    const response =
      await CartApiServiceService.getApiServicesAppCartApiServiceGetSubscriptions(
        { cartId }
      );

    runInAction(() => {
      this.subscriptionsInformation = response;
    });
  };

  getAvailableProducts = async (
    cartId: string,
    uuid: string
  ): Promise<void> => {
    await CartApiServiceService.getApiServicesAppCartApiServiceGetAvailableProducts(
      { cartId, requestUuid: uuid }
    ).then((response) => {
      runInAction(() => {
        this.availableProducts = response;
      });
    });
  };

  addProductsToCart = async (
    cartId: string,
    uuid: string,
    requestBody: ProductInputDto[]
  ): Promise<boolean> => {
    const response =
      await CartApiServiceService.postApiServicesAppCartApiServiceAddProductToCart(
        { cartId, requestUuid: uuid, requestBody }
      ).catch((error) => error.response.data.error.message);

    return response;
  };

  updateProductsInCart = async (
    cartId: string,
    uuid: string,
    requestBody: ProductInputDto[]
  ): Promise<boolean> => {
    const response =
      await CartApiServiceService.postApiServicesAppCartApiServiceReplaceProductsInCart(
        { cartId, requestUuid: uuid, requestBody }
      ).catch((error) => error.response.data.error.message);

    return response;
  };

  cleanProductsInCart = async (
    cartId: string,
    uuid: string,
    productIds: string[]
  ): Promise<void> => {
    const response =
      await CartApiServiceService.deleteApiServicesAppCartApiServiceRemoveProductsFromCart(
        { cartId, requestUuid: uuid, productIds }
      );
  };

  addSpecialRequest = async (
    cartId: string,
    uuid: string,
    requestBody: SpecialRequestInputDto
  ): Promise<boolean> => {
    const response =
      await CartApiServiceService.postApiServicesAppCartApiServiceAddSpecialRequestToCart(
        { cartId, requestUuid: uuid, requestBody }
      ).catch((error) => error.response.data.error.message);

    return response;
  };

  getOrder = async (cartId: string, uuid: string): Promise<any> => {
    await CartApiServiceService.getApiServicesAppCartApiServiceGetOrders({
      cartId,
      requestUuid: uuid,
    }).then((response) => {
      runInAction(() => {
        this.order = response;
      });
    });
  };
}
