import { useContext } from 'react';
import { useStore } from 'zustand';
import { StoreState } from './createBrandConfigStore';
import { BrandConfigContext } from './storeProvider';

export function useBrandConfigStore<T>(selector: (state: StoreState) => T): T {
  const store = useContext(BrandConfigContext);
  if (!store)
    throw new Error('Missing BrandConfigContext.Provider in the tree');
  return useStore(store, selector);
}
