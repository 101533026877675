import { useState } from 'react';
import useMediaQuery from './useMediaQuery';

const value = 1024
const defaultBreakpoint = `${value}px`;

export const useIsMobile = (breakpoint?: string): boolean => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= value);
  useMediaQuery(
    `(max-width: ${breakpoint ? breakpoint : defaultBreakpoint})`,
    setIsMobile
  );
  return isMobile;
};
