import { ReactNode, createContext, useRef } from 'react';
import {
  BrandConfigurationStore,
  createBrandConfigStore,
} from './createBrandConfigStore';

type StoreProviderProps = {
  children: ReactNode;
  brandConfig: any;
};

export const BrandConfigContext = createContext<BrandConfigurationStore | null>(
  null
);

export function StoreProvider({ children, brandConfig }: StoreProviderProps) {
  const storeRef = useRef<BrandConfigurationStore>();

  if (!storeRef.current) {
    storeRef.current = createBrandConfigStore(brandConfig);
  }

  return (
    <BrandConfigContext.Provider value={storeRef.current}>
      {children}
    </BrandConfigContext.Provider>
  );
}
