import { createContext, useContext } from 'react';
import SessionStore from './sessionStore';
import AuthenticationStore from './authenticationStore';
import AccountStore from './accountStore';
import ShoppingSelectionStore from './Romulo/shoppingSelectionStore';
import TransportSelectionStore from './Romulo/transportSelectionStore';
import SearchLocationsStore from './Romulo/searchLocationsStore';
import CartApiStore from './Romulo/cartApiStore';
import ReservationStore from './Welfare/reservation';

export const stores = {
  //CORE
  authenticationStore: new AuthenticationStore(),
  sessionStore: new SessionStore(),
  accountStore: new AccountStore(),

  //ROMULO
  searchLocationsStore: new SearchLocationsStore(),
  shoppingSelectionStore: new ShoppingSelectionStore(),
  transportSelectionStore: new TransportSelectionStore(),
  cartApiStore: new CartApiStore(),

  //WELFARE
  reservationStore: new ReservationStore(),
};

export const StoreContext = createContext(stores);

const useStores = () => {
  return useContext(StoreContext);
};

export default useStores;
