import { useTranslate } from '@tolgee/react';
import { FC, Fragment, useEffect, useState } from 'react';
import * as Styled from './CookiesBanner.styled';
import { useBrandConfigStore } from '../../stores/Zustand/useStore';
import { Divider, Switch } from 'antd';
import { DownOutlined, LeftOutlined, UpOutlined } from '@ant-design/icons';

import cookiesTypes from '../../utils/dictionaries/cookiesTypes.json';
import { useIsMobile } from '../../hooks/useIsMobile';

interface CookiesBannerModalProps {
  closeModal: () => void;
  acceptCookies: (acceptedCookies: 'all' | 'required' | string[]) => void;
}

const CookiesBannerModal: FC<CookiesBannerModalProps> = ({
  closeModal,
  acceptCookies,
}) => {
  const { t } = useTranslate();
  const brandConfig = useBrandConfigStore((s) => s.brandConfig);
  const cookiesPolicy = brandConfig.footer.custom.cookiesUrl;
  const { headerColor, primaryColorBgTextColor, accentColor, primaryColor } =
    brandConfig.theme;

  const [selectedCookies, setSelectedCookies] = useState<string[]>([]);
  const [openedDescriptions, setOpenedDescriptions] = useState<string[]>([]);

  const isMobile = useIsMobile();

  useEffect(() => {
    const initialCookies = cookiesTypes.map((i) => i.key);
    setSelectedCookies(initialCookies);
  }, []);

  const mainColor =
    headerColor.toLowerCase() === '#ffffff'
      ? primaryColor.toLowerCase() === '#ffffff'
        ? accentColor
        : primaryColor
      : headerColor;
  const textColor =
    headerColor.toLowerCase() === '#ffffff'
      ? primaryColor.toLowerCase() === '#ffffff'
        ? primaryColorBgTextColor
        : '#ffffff'
      : '#ffffff';

  const updateSelectedCookies = (newValue: boolean, cookieType: string) => {
    const requiredCookies = cookiesTypes.map((i) => i.required && i.key);
    const newCookies = () => {
      if (requiredCookies.includes(cookieType)) {
        return selectedCookies;
      }

      if (selectedCookies.includes(cookieType)) {
        return newValue
          ? selectedCookies
          : selectedCookies.filter((i) => i !== cookieType);
      }

      return newValue ? selectedCookies.concat(cookieType) : selectedCookies;
    };

    setSelectedCookies(newCookies());
  };

  const ShowDescription = ({ type }) => (
    <Styled.ShowDescription
      onClick={() => {
        const newOpenedDescriptions = openedDescriptions.includes(type.key)
          ? openedDescriptions.filter((i) => i !== type.key)
          : openedDescriptions.concat(type.key);
        setOpenedDescriptions(newOpenedDescriptions);
      }}
    >
      {openedDescriptions.includes(type.key) ? (
        <>
          {t('cookies_consent.modal.button.hide_description')} <UpOutlined />
        </>
      ) : (
        <>
          {t('cookies_consent.modal.button.show_description')} <DownOutlined />
        </>
      )}
    </Styled.ShowDescription>
  );

  return (
    <Styled.ManageCookiesBanner>
      <Styled.ManageCookiesContainer>
        <Styled.ModalHeaderButtons>
          <Styled.BackButton onClick={closeModal}>
            <LeftOutlined />
          </Styled.BackButton>
          {cookiesPolicy && (
            <Styled.FullDocumentButton href={cookiesPolicy} target="_blank">
              {t('cookies_consent.modal.button.show_full_cookies_policy')}
            </Styled.FullDocumentButton>
          )}
        </Styled.ModalHeaderButtons>
        <Styled.ModalContent>
          <Styled.CookiesSection>
            <Styled.Title>{t('cookies_consent.modal.main.title')}</Styled.Title>
            <Styled.Text>
              {t('cookies_consent.modal.main.description')}
            </Styled.Text>
            <Styled.AllCookiesButtons>
              <Styled.ButtonSecondary onClick={() => acceptCookies('required')}>
                <Styled.RejectIcon />
                {t('cookies_consent.modal.button.reject_all')}
              </Styled.ButtonSecondary>
              <Styled.ButtonPrimary
                $mainColor={mainColor}
                $textColor={textColor}
                $size="fit"
                onClick={() => acceptCookies('all')}
              >
                <Styled.AcceptIcon $color={textColor} />
                {t('cookies_consent.modal.button.accept_all')}
              </Styled.ButtonPrimary>
            </Styled.AllCookiesButtons>
          </Styled.CookiesSection>
          <Divider />
          <Styled.CookiesSection>
            <Styled.Title>
              {t('cookies_consent.modal.preferences.title')}
            </Styled.Title>
            <Styled.Text>
              {t('cookies_consent.modal.preferences.description')}
            </Styled.Text>
          </Styled.CookiesSection>
          <Divider />
          {cookiesTypes.map((type, key) => {
            return (
              <Fragment key={key}>
                <Styled.CookiesSection>
                  <Styled.CookieTypeSelector>
                    <Styled.CookieTypeTitle>
                      {t(type.name)}
                      {isMobile && <ShowDescription type={type} />}
                    </Styled.CookieTypeTitle>
                    <Styled.InfoAndSwitch>
                      {!isMobile && <ShowDescription type={type} />}
                      <Switch
                        checked={selectedCookies.includes(type.key)}
                        onChange={(e) => updateSelectedCookies(e, type.key)}
                      />
                    </Styled.InfoAndSwitch>
                  </Styled.CookieTypeSelector>
                  {openedDescriptions.includes(type.key) && (
                    <Styled.Text>{t(type.description)}</Styled.Text>
                  )}
                </Styled.CookiesSection>
                <Divider />
              </Fragment>
            );
          })}
        </Styled.ModalContent>
        <Styled.AllCookiesButtons>
          <Styled.ButtonPrimary
            $mainColor={mainColor}
            $textColor={textColor}
            $size="fit"
            onClick={() => acceptCookies(selectedCookies)}
          >
            {t('cookies_consent.modal.button.save_and_continue')}
          </Styled.ButtonPrimary>
        </Styled.AllCookiesButtons>
      </Styled.ManageCookiesContainer>
    </Styled.ManageCookiesBanner>
  );
};

export default CookiesBannerModal;
