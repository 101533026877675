export const updateFavicon = (faviconUrl: string) => {
  const linkElements = [];
  var head = document.getElementsByTagName('head')[0];

  const linkEl = document.createElement('link');
  linkEl.type = 'image/x-icon';
  linkEl.rel = 'icon';
  linkEl.href = faviconUrl;

  linkElements.push(linkEl);

  var links = head.getElementsByTagName('link');
  for (var i = links.length; --i >= 0; ) {
    if (/\bicon\b/i.test(links[i].getAttribute('rel'))) {
      head.removeChild(links[i]);
    }
  }

  linkElements.forEach((el) => head.appendChild(el));
};
