import AppConsts from '../../lib/appconst';
import abpUserConfigurationService from '../../services/abpUserConfigurationService';
import { StrapiService } from '../../services/codegen-welfare';
import utils from '../utils';

export interface BrandConfigResponse {
  id: string;
  domain: string;
  brandConfig: any;
}

const hostname =
  AppConsts.domainSelectionForLocalhost || window.location.hostname;

const brandConfigurationParams = [
  '*',
  'products',
  'brandDetails.footer',
  'brandDetails.footer.logo',
  'paymentMethods',
  'landingContent',
  'landingContent.hero',
  'landingContent.hero.hero_background',
  'landingContent.productExplanation',
  'theme.favicon',
  'theme.logoPrimaryUrl',
  'theme.logoSecondaryUrl',
  'payment_methods',
  'hasTokenAuthentication',
  'checkoutOptions',
  'promo_campaign',
  'promo_campaign.bannerDP',
  'promo_campaign.bannerH',
  'promo_campaign.bannerF',
];

const brandConfigurationParamsString = brandConfigurationParams
  .map((value, index) => `populate[${index}]=${value}`)
  .join('&');

const brandConfig = await StrapiService.getApiServicesAppStrapiGetConfig({
  path: `brand-configurations/${hostname}?${brandConfigurationParamsString}`,
}).then(async (configuration): Promise<BrandConfigResponse> => {
  const configId = configuration.data.id;
  const domain = configuration.data.attributes.domain;
  const language = configuration.data.attributes.brandDetails.language;

  // TODO REMOVE
  const abpConfig = await abpUserConfigurationService.getAll({
    language: language,
  });
  utils.extend(true, abp, abpConfig.data.result);

  return {
    id: configId,
    domain: domain,
    brandConfig: configuration.data.attributes,
  };
});

export { brandConfig };
