import LoadableComponent from '../../Loadable';

export default [
  {
    path: '/search?',
    name: 'search',
    title: 'search.page_title',
    component: LoadableComponent(
      () => import('../../../scenes/Search/SearchContainer')
    ),
    showInMenu: false,
  },
  {
    path: '/booking/:departure/:destination/:outboundDate/:returnDate',
    name: 'flight-hotel-results',
    title: 'promo.search_meta_title.inspiring_ideas_for_24_25',
    component: LoadableComponent(
      () => import('../../BookingFlowRouter/BookingFlowRouter')
    ),
    showInMenu: false,
  },
  {
    path: '/booking/:destination/:outboundDate/:returnDate',
    name: 'hotel-results',
    title: 'promo.search_meta_title.inspiring_ideas_for_24_25',
    component: LoadableComponent(
      () => import('../../BookingFlowRouter/BookingFlowRouter')
    ),
    showInMenu: false,
  },
  {
    path: '/order',
    name: 'order',
    title: window.location.href.includes('success=false')
      ? 'error.generic_error'
      : 'order_completed.thank_you_short',
    component: LoadableComponent(
      () => import('../../../scenes/OrderComplete/OrderComplete')
    ),
    showInMenu: false,
  },
];
