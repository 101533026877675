import { createStore } from 'zustand';
import { BrandConfig, configMapper } from './configMapper';

export type BrandConfigurationStore = ReturnType<typeof createBrandConfigStore>;

export interface StoreState {
  brandConfig?: BrandConfig;
}

export const createBrandConfigStore = (brandConfig: any) => {
  return createStore<StoreState>()(() => ({
    brandConfig: configMapper(brandConfig),
  }));
};
