import { Sort } from '../../../services/codegen-romulo';

const getSortKeyByTabIndex = (tabIndex: string): Sort => {
  switch (tabIndex) {
    case 'RECOMMENDED':
      return { name: 'RECOMMENDED', ascending: true };
    case 'PRICE':
      return { name: 'PRICE', ascending: true };
    case 'DURATION':
      return { name: 'DURATION', ascending: true };
    case 'TOP_RATED':
      return { name: 'TOP_RATED', ascending: false };
    case 'TRANSPORT-PRICE':
      return { name: 'TRANSPORT-PRICE', ascending: true };
    case 'TRANSPORT-RECOMMENDED':
      return { name: 'TRANSPORT-RECOMMENDED', ascending: true };
    case 'TRANSPORT-FASTEST':
      return { name: 'TRANSPORT-FASTEST', ascending: true };
  }
  return { name: 'RECOMMENDED', ascending: false };
};

export default getSortKeyByTabIndex;
