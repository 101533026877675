import { explodeDuration } from './timeUtils';

const formatHour = (hour: number) => {
  const duration = explodeDuration(hour);
  return `${duration.hours < 10 ? `0${duration.hours}` : duration.hours}:${
    duration.minutes < 10 ? `0${duration.minutes}` : duration.minutes
  }`;
};

export default formatHour;
