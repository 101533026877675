/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdvancedSearchInput } from '../models/AdvancedSearchInput';
import type { SearchTransportInputDto } from '../models/SearchTransportInputDto';
import type { TransportProposalResponseDto } from '../models/TransportProposalResponseDto';
import type { TransportResponseAdvanceUpdated } from '../models/TransportResponseAdvanceUpdated';
import type { TransportSearchResponseDto } from '../models/TransportSearchResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ShoppingTransportServiceService {

    /**
     * @returns TransportSearchResponseDto Success
     * @throws ApiError
     */
    public static postApiServicesAppShoppingTransportServiceCreateSearch({
        requestBody,
    }: {
        requestBody?: SearchTransportInputDto,
    }): CancelablePromise<TransportSearchResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/ShoppingTransportService/CreateSearch',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns TransportResponseAdvanceUpdated Success
     * @throws ApiError
     */
    public static postApiServicesAppShoppingTransportServiceRetrieveProposals({
        searchId,
        requestBody,
    }: {
        searchId?: string,
        requestBody?: AdvancedSearchInput,
    }): CancelablePromise<TransportResponseAdvanceUpdated> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/ShoppingTransportService/RetrieveProposals',
            query: {
                'searchId': searchId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns TransportProposalResponseDto Success
     * @throws ApiError
     */
    public static getApiServicesAppShoppingTransportServiceGetTransportProposalByProposalId({
        proposalId,
    }: {
        proposalId?: string,
    }): CancelablePromise<TransportProposalResponseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/services/app/ShoppingTransportService/GetTransportProposalByProposalId',
            query: {
                'proposalId': proposalId,
            },
        });
    }

}
