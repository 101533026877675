import { useEffect } from 'react';

const useMediaQuery = (breakpoint: string, onMediaQueryChange: (matches: boolean) => void): void => {
  function mobileMediaQueryEvent(mediaQuery: MediaQueryListEvent | MediaQueryList): void {
    onMediaQueryChange(mediaQuery.matches);
  }

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia(breakpoint);
    mobileMediaQueryEvent(mobileMediaQuery);

    if (mobileMediaQuery.addEventListener) {
      mobileMediaQuery.addEventListener('change', mobileMediaQueryEvent);
    } else {
      mobileMediaQuery.addListener(mobileMediaQueryEvent); // needed for Safari 13
    }
  }, []);
};

export default useMediaQuery;
