import { ReactNode, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useBrandConfigStore } from '../../stores/Zustand/useStore';
import useStores from '../../stores/stores';
import { getSearchParam } from '../../utils/getSearchParam';

const ProtectedRoute = ({ children }): ReactNode => {
  const [hasError, setHasError] = useState(false);
  const brandConfig = useBrandConfigStore((s) => s.brandConfig);
  const { domain, brand } = brandConfig;

  const { authenticationStore } = useStores();
  const { authenticateExternal } = authenticationStore;

  const getAuthenticationDetails = () => {
    const urlToken = getSearchParam('a');
    const token = encodeURIComponent(urlToken === null ? '' : urlToken);
    if (token || token === '') {
      authenticateExternal({
        token: decodeURIComponent(token.replaceAll('%20', '+')),
        domainName: domain,
      }).catch(() => setHasError(true));
    } else {
      setHasError(true);
    }
  };

  useEffect(() => {
    getAuthenticationDetails();
  }, []);

  if (hasError) {
    return (
      <Navigate
        to={{
          pathname: '/exception',
          search: 'type=401',
        }}
      />
    );
  }

  return children;
};

export default ProtectedRoute;
