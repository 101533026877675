import { makeAutoObservable, runInAction } from 'mobx';
import { FlightResultFilters } from '../../models/Romulo/FlightResultFilters';
import getSortKeyByTabIndex from '../../scenes/FlightsResult/utils/getSortKeyByTabIndex';
import {
  AdvancedSearchInput,
  SearchTransportInputDto,
  ShoppingTransportServiceService,
  TransportResponseAdvanceUpdated,
} from '../../services/codegen-romulo';
import formatHour from '../../utils/formatHours';
import { getSearchParam } from '../../utils/getSearchParam';
import { brandConfig } from '../../utils/configurations/brandConfig';

export default class TransportSelectionStore {
  flightResultFilters: FlightResultFilters = { combineProviders: true };
  sortingIndex = 'RECOMMENDED';
  resultPage = Number(1);
  searchId?: string;
  loadingFlightResults = true;
  searchFlightProposals?: TransportResponseAdvanceUpdated;

  getFlightsRetries?: number;

  QPSearchId = getSearchParam('searchId');

  constructor() {
    makeAutoObservable(this);
  }

  createFlightSearchId = async (
    requestBody: SearchTransportInputDto
  ): Promise<string> => {
    const response =
      await ShoppingTransportServiceService.postApiServicesAppShoppingTransportServiceCreateSearch(
        { requestBody }
      );

    runInAction(() => {
      this.searchId = response?.searchId;
    });

    return response?.searchId;
  };

  getProposals = async () => {
    this.loadingFlightResults = true;
    const selectedFilters = this.flightResultFilters;
    const priceRange =
      selectedFilters.budgetRange?.length > 0
        ? [
            {
              name: 'price',
              range: {
                from: `${selectedFilters.budgetRange[0]}`,
                to: `${selectedFilters.budgetRange[1]}`,
              },
            },
          ]
        : [];

    const outboundStops =
      selectedFilters.outboundStops && selectedFilters.outboundStops.length > 0
        ? [
            {
              name: 'outboundStops',
              values: selectedFilters.outboundStops,
            },
          ]
        : [];
    const inboundStops =
      selectedFilters.inboundStops && selectedFilters.inboundStops.length > 0
        ? [
            {
              name: 'inboundStops',
              values: selectedFilters.inboundStops,
            },
          ]
        : [];

    const luggage =
      selectedFilters.luggageOptions &&
      selectedFilters.luggageOptions.length > 0
        ? [
            {
              name: 'baggageIncluded',
              values:
                selectedFilters.luggageOptions.length === 2
                  ? ['CABIN_CHECKED']
                  : ['CABIN_CHECKED', ...selectedFilters.luggageOptions],
            },
          ]
        : [];

    const outboundDepartureTime =
      selectedFilters.outboundDepartureTime &&
      selectedFilters.outboundDepartureTime.length > 0
        ? [
            {
              name: 'outboundDepartureTime',
              range: {
                from: formatHour(selectedFilters.outboundDepartureTime[0]),
                to: formatHour(selectedFilters.outboundDepartureTime[1]),
              },
            },
          ]
        : [];

    const inboundDepartureTime =
      selectedFilters.inboundDepartureTime &&
      selectedFilters.inboundDepartureTime.length > 0
        ? [
            {
              name: 'inboundDepartureTime',
              range: {
                from: formatHour(selectedFilters.inboundDepartureTime[0]),
                to: formatHour(selectedFilters.inboundDepartureTime[1]),
              },
            },
          ]
        : [];

    const connectionTime = selectedFilters.connectionTime
      ? [
          {
            name: 'connectionTime',
            range: {
              from: '0',
              to: `${selectedFilters.connectionTime}`,
            },
          },
        ]
      : [];

    const tripDuration = selectedFilters.tripDuration
      ? [
          {
            name: 'tripDuration',
            range: {
              from: '0',
              to: `${selectedFilters.tripDuration}`,
            },
          },
        ]
      : [];

    const airlines =
      selectedFilters.airlines && selectedFilters.airlines.length > 0
        ? [
            {
              name: selectedFilters.combineProviders
                ? 'inclusiveProviders'
                : 'exclusiveProviders',
              values: selectedFilters.airlines,
            },
          ]
        : [];

    const outboundDepartureLocation =
      selectedFilters.outboundDepartureLocation &&
      selectedFilters.outboundDepartureLocation.length > 0
        ? [
            {
              name: 'outboundDepartureLocation',
              values: selectedFilters.outboundDepartureLocation,
            },
          ]
        : [];

    const outboundArrivalLocation =
      selectedFilters.outboundArrivalLocation &&
      selectedFilters.outboundArrivalLocation.length > 0
        ? [
            {
              name: 'outboundArrivalLocation',
              values: selectedFilters.outboundArrivalLocation,
            },
          ]
        : [];

    const inboundArrivalLocation =
      selectedFilters.inboundArrivalLocation &&
      selectedFilters.inboundArrivalLocation.length > 0
        ? [
            {
              name: 'inboundArrivalLocation',
              values: selectedFilters.depArrSameLocation
                ? selectedFilters.outboundDepartureLocation
                : selectedFilters.inboundArrivalLocation,
            },
          ]
        : [];

    const inboundDepartureLocation =
      selectedFilters.inboundDepartureLocation &&
      selectedFilters.inboundDepartureLocation.length > 0
        ? [
            {
              name: 'inboundDepartureLocation',
              values: selectedFilters.depArrSameLocation
                ? selectedFilters.outboundArrivalLocation
                : selectedFilters.inboundDepartureLocation,
            },
          ]
        : [];

    const onlyFlight = [{ name: 'transportType', values: ['ONLY_FLIGHT'] }];
    const sortKey = getSortKeyByTabIndex(this.sortingIndex);

    const alternativeTransportsRequest: AdvancedSearchInput = {
      facets: [
        ...onlyFlight,
        ...priceRange,
        ...luggage,
        ...outboundStops,
        ...inboundStops,
        ...outboundDepartureTime,
        ...inboundDepartureTime,
        ...connectionTime,
        ...tripDuration,
        ...airlines,
        ...outboundDepartureLocation,
        ...outboundArrivalLocation,
        ...inboundArrivalLocation,
        ...inboundDepartureLocation,
      ],
      sort: sortKey,
      page: { size: 20, number: this.resultPage },
      filtersMetadata: true,
      staticData: {
        language: brandConfig.brandConfig.brandDetails.language || 'en',
      },
    };

    try {
      const response =
        await ShoppingTransportServiceService.postApiServicesAppShoppingTransportServiceRetrieveProposals(
          {
            searchId: this.searchId || this.QPSearchId,
            requestBody: alternativeTransportsRequest,
          }
        );

      runInAction(() => {
        this.searchFlightProposals = response;
        this.loadingFlightResults = false;
      });
    } catch (error) {
      if (
        error.response.data.error.message === 'GENERIC_ERROR' &&
        this.getFlightsRetries < 10
      ) {
        this.getProposals();
        runInAction(() => {
          this.getFlightsRetries = this.getFlightsRetries + 1;
        });
      } else {
        runInAction(() => {
          this.searchFlightProposals = {
            proposals: [],
          };
          this.loadingFlightResults = false;
        });
      }
    }
  };

  resetAllFlightProposals = () => {
    this.searchFlightProposals = undefined;
  };
  resetFlightSearchId = () => {
    this.searchId = undefined;
  };

  updateFlightResultsFilters = async (filters: any) => {
    this.resultPage = 1;
    this.loadingFlightResults = true;
    this.flightResultFilters = filters;
    await this.getProposals();
  };

  clearFlightResultsFilters = async () => {
    this.loadingFlightResults = true;
    this.flightResultFilters = {};
    await this.getProposals();
  };

  updateSortingIndex = async (newIndex: string) => {
    this.loadingFlightResults = true;
    this.sortingIndex = newIndex;
    await this.getProposals();
  };

  updatePage = async (pageNumber: number) => {
    this.loadingFlightResults = true;
    this.resultPage = pageNumber;
    await this.getProposals();
  };
}
