import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import DocumentTitle from 'react-document-title';
import { styled } from 'styled-components';
import routers from '../../../components/Router/router.config';
import { useTranslate } from '@tolgee/react';

const StyledLayout = styled(Layout)`
  min-height: 100vh;
  min-width: 100%;
  padding: 0px;
`;

const getRoute = (path: string): any => {
  return routers.filter((route) => route.path === path)[0];
};

export default function TravelLayout(props: { path: string }) {
  const routeContent = props.path && getRoute(props.path);
  const RouteComponent = routeContent.component;
  const { t } = useTranslate();

  return (
    <DocumentTitle title={`${t(routeContent.title)}`}>
      <StyledLayout>
        <Content>
          <RouteComponent />
        </Content>
      </StyledLayout>
    </DocumentTitle>
  );
}
