import * as React from 'react'
import './style.css'

import { Spin } from 'antd'

const Loading = () => {
    return (
        <div
            style={{ paddingTop: 100, textAlign: 'center' }}
            className={'roiward-loader'}
        >
            <Spin size="large" />
        </div>
    )
}

export default Loading
