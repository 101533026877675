import type { ThemeConfig } from 'antd';

const baseTheme: ThemeConfig = {
  token: {
    borderRadius: 8,
    wireframe: false,
    boxShadow:
      '0px 10px 20px rgba(0,0,0,0.04),0px 2px 10px rgba(0,0,0,0.04),0px 0px 4px rgba(0,0,0,0.02)',
    boxShadowSecondary:
      '0px 24px 32px rgba(0,0,0,0.04),0px 16px 24px rgba(0,0,0,0.04),0px 4px 8px rgba(0,0,0,0.04),0px 0px 10px rgba(0,0,0,0.04)',
    colorLink: '#40b1f5',
    colorInfo: '#0078d4',
    colorSuccess: '#65b700',
    colorWarning: '#ffaa06',
    colorError: '#f2142a',
    colorTextBase: '#3c3c3c',
  },
  components: {
    Card: {
      borderRadiusLG: 16,
      colorBorderSecondary: 'rgb(234, 234, 235)',
      colorFillAlter: 'rgb(255, 255, 255)',
    },
    Rate: {
      yellow6: '#FFB511',
    },
    Button: {
      controlHeight: 40,
      controlHeightLG: 48,
      fontWeight: 700,
      defaultColor: 'rgb(254, 230, 242)',
      defaultBorderColor: 'rgb(254, 230, 242)',
      colorBorder: 'rgb(245, 245, 245)',
      boxShadow:
        'rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.06) 0px 0px 2px, rgba(0, 0, 0, 0.04) 0px 0px 1px',
    },
    Typography: {
      fontFamilyCode:
        "'Open Sans', 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace",
    },
    DatePicker: {
      borderRadiusLG: 8,
      borderRadius: 8,
      controlHeight: 50,
      paddingXXS: 0,
      colorBgContainer: 'rgb(245, 245, 245)',
      colorText: 'rgb(60, 60, 60)',
      colorPrimaryBorder: 'rgb(255, 122, 178)',
      hoverBorderColor: 'rgb(60, 60, 60)',
      colorBorder: 'rgb(245, 245, 245)',
      activeShadow: '0 0 0 1px rgba(60,60,60,0)',
      activeBorderColor: 'rgb(60,60,60)',
      colorTextPlaceholder: '#74747c',
      fontSize: 16,
    },
    Input: {
      activeBg: 'rgb(245, 245, 245)',
      colorPrimary: 'rgb(60, 60, 60)',
      controlHeight: 52,
      borderRadius: 8,
      colorBgContainer: 'rgb(245, 245, 245)',
      controlOutline: 'rgba(0, 0, 0, 0)',
      colorBorder: 'rgb(245, 245, 245)',
      colorPrimaryHover: 'rgb(186, 186, 192)',
      fontSize: 16,
      hoverBorderColor: 'rgb(60, 60, 60)',
      colorTextPlaceholder: '#74747c',
    },
    InputNumber: {
      hoverBorderColor: 'rgb(60, 60, 60)',
      activeBg: 'rgb(245, 245, 245)',
      colorBgContainer: 'rgb(245, 245, 245)',
      colorBorder: 'rgb(245, 245, 245)',
      colorPrimaryHover: 'rgb(186, 186, 192, 0)',
      colorPrimary: 'rgb(60, 60, 60)',
      controlHeight: 52,
      borderRadius: 8,
      fontSize: 16,
      colorTextPlaceholder: '#74747c',
    },
    Select: {
      borderRadiusLG: 8,
      colorBgContainer: 'rgb(245, 245, 245)',
      colorBorder: 'rgb(245, 245, 245)',
      controlHeight: 52,
      colorPrimaryHover: 'rgb(60, 60, 60)',
      borderRadius: 8,
      fontSize: 16,
      colorPrimary: 'rgb(60, 60, 60)',
      colorTextPlaceholder: '#74747c',
    },
    Alert: {
      colorInfoBorder: '#ecf7fe',
      colorInfoTextActive: '#0078d4',
      colorInfoText: '#0078d4',
      colorInfoBg: '#ecf7fe',
      defaultPadding: '16px !important',
    },
  },
};

export { baseTheme };
