import {
  action,
  observable,
  makeObservable,
  runInAction,
  makeAutoObservable,
} from 'mobx';
import AppConsts from '../lib/appconst';

import LoginModel from '../models/Login/loginModel';
import tokenAuthServiceLegacy from '../services/tokenAuth/tokenAuthService';
import {
  AuthenticatedDto,
  AuthenticatorInputDto,
} from '../services/codegen-welfare';

declare var abp: any;

class AuthenticationStore {
  loginModel: LoginModel = new LoginModel();

  authExternalInfo?: AuthenticatedDto;

  authExternalInfoError?: boolean;

  constructor() {
    makeAutoObservable(this);
  }

  get isAuthenticated(): boolean {
    if (!abp.session.userId) return false;
    return true;
  }

  public async login(model: LoginModel) {
    const result = await tokenAuthServiceLegacy.authenticate({
      userNameOrEmailAddress: model.userNameOrEmailAddress,
      password: model.password,
      rememberClient: model.rememberMe,
    });

    var tokenExpireDate = model.rememberMe
      ? new Date(new Date().getTime() + 1000 * result.expireInSeconds)
      : undefined;
    abp.auth.setToken(result.accessToken, tokenExpireDate);
    abp.utils.setCookieValue(
      AppConsts.authorization.encrptedAuthTokenName,
      result.encryptedAccessToken,
      tokenExpireDate,
      abp.appPath
    );
  }

  authenticateExternal = async (request: AuthenticatorInputDto) => {
    const result = await tokenAuthServiceLegacy.authenticateExternal(request);

    var tokenExpireDate = new Date(
      new Date().getTime() + 1000 * result.authApiModel.expireInSeconds
    );
    abp.auth.setToken(result.authApiModel.accessToken, tokenExpireDate);
    abp.utils.setCookieValue(
      AppConsts.authorization.encrptedAuthTokenName,
      result.authApiModel.encryptedAccessToken,
      tokenExpireDate,
      abp.appPath
    );
    runInAction(() => {
      if (result.authApiModel.accessToken) {
        this.authExternalInfo = result;
      } else {
        this.authExternalInfoError = true;
      }
    });

    return result;
  };

  logout(): void {
    localStorage.clear();
    sessionStorage.clear();
    abp.auth.clearToken();
  }
}

export default AuthenticationStore;
