import { Flex } from 'antd';
import Title from 'antd/es/typography/Title';
import DocumentTitle from 'react-document-title';
import { styled } from 'styled-components';
import { HelpMessageDev } from '../../components/HelpMessageDev/HelpMessageDev';
import StyledCard from '../../components/StyledComponents/StyledCard';
import { useTranslate } from '@tolgee/react';

const { StyledCardLarge } = StyledCard;

const StyledImage = styled.div<{ $url: string }>`
  background-image: url(${({ $url }) => $url});
  height: 150px;
  width: 200px;
  border-radius: 8px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;

const StyledP = styled.p`
  margin: 0px;
`;

const Exception = ({ match }: any) => {
  const { t } = useTranslate();
  const exception = [
    {
      errorCode: '404',
      title: 'error.code.400.page_not_found',
      errorDescription: 'error.code.400.page_not_found.description',
    },
    {
      errorCode: '401',
      title: 'error.code.401.no_access',
      errorDescription: 'error.code.401.no_access.description',
    },
    {
      errorCode: '500',
      title: 'error.code.500.internal_error',
      errorDescription: 'error.code.500.internal_error.description',
    },
  ];

  const params = new URLSearchParams(document.location.search);
  const type = params.get('type');
  const error = exception.find((x) => x.errorCode === type);

  return (
    <DocumentTitle title={`${t('error.page')}`}>
      <Flex
        style={{ width: '100%', height: '100vh', backgroundColor: '#f5f5f5' }}
        justify="center"
        align="center"
        vertical
      >
        <StyledCardLarge
          style={{ maxWidth: 400, width: '100%' }}
          $borderColor={'white'}
        >
          <Flex gap={16} vertical align="center">
            <StyledImage
              $url={
                'https://assets.staticroot.com/lmn-assets-ui/4/alert/generic-error/pink.svg'
              }
            />
            <div>
              <Title level={2} style={{ margin: 0, textAlign: 'center' }}>
                {`${error.errorCode} - ${t(error.title)}`}
              </Title>
              <StyledP>{t(error.errorDescription)}</StyledP>
            </div>
          </Flex>
        </StyledCardLarge>
        <HelpMessageDev />
      </Flex>
    </DocumentTitle>
  );
};

export default Exception;
