export const localeObject = {
  it: {
    name: 'it',
    weekdays: 'Domenica_Lunedì_Martedì_Mercoledì_Giovedì_Venerdì_Sabato'.split(
      '_'
    ),
    weekStart: 1,
    months:
      'Gennaio_Febbraio_Marzo_Aprile_Maggio_Giugno_Luglio_Agosto_Settembre_Ottobre_Novembre_Dicembre'.split(
        '_'
      ),
  },
  es: {
    name: 'es',
    weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
    weekStart: 1,
    months:
      'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
        '_'
      ),
  },
  en: {
    name: 'en',
    weekdays: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split(
      '_'
    ),
    weekStart: 1,
    months:
      'January_February_March_April_May_June_July_August_September_October_November_December'.split(
        '_'
      ),
  },
};
