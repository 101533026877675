import { Button, Flex } from 'antd';
import Title from 'antd/es/typography/Title';
import { FC } from 'react';
import { styled } from 'styled-components';
import StyledCard from '../StyledComponents/StyledCard';
const { StyledCardLarge } = StyledCard;

const StyledP = styled.p`
  margin: 0px;
`;
const StyledImage = styled.div<{ $url: string }>`
  background-image: url(${({ $url }) => $url});
  height: 150px;
  width: 200px;
  border-radius: 8px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;

const FullScreenError: FC<{
  title: string;
  subtitle: string;
  ctaLabel: string;
}> = ({ title, subtitle, ctaLabel }) => {
  return (
    <Flex
      style={{ width: '100%', height: '100vh', backgroundColor: '#f5f5f5' }}
      justify="center"
      align="center"
      vertical
    >
      <StyledCardLarge style={{ width: 400 }} $borderColor={'white'}>
        <Flex gap={16} vertical align="center">
          <StyledImage
            $url={
              'https://assets.staticroot.com/lmn-assets-ui/4/alert/generic-error/pink.svg'
            }
          />
          <div>
            <Title level={2} style={{ margin: 0, textAlign: 'center' }}>
              {title}
            </Title>
            <StyledP style={{ textAlign: 'center' }}>{subtitle}</StyledP>
          </div>
          <Button
            type="primary"
            key="console"
            block
            onClick={() => window.location.reload()}
          >
            {ctaLabel}
          </Button>
        </Flex>
      </StyledCardLarge>
    </Flex>
  );
};

export default FullScreenError;
