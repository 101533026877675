import AppConsts from './appconst'

declare var abp: any

export function L(
    key: string,
    sourceName?: string,
    replaceString?: { key: string; value: string }[]
): string {
    const localizationSourceName =
        AppConsts.localization.defaultLocalizationSourceName
    const translation = abp.localization.localize(
        key,
        sourceName || localizationSourceName
    )

    if (translation) {
        if (replaceString?.length > 0) {
            let newString = ''
            replaceString.map((item, index) => {
                if (index === 0) {
                    newString = translation.replaceAll(item.key, item.value)
                } else {
                    newString = newString.replaceAll(item.key, item.value)
                }
            })
            return newString
        }
    }

    return translation
}

export function isGranted(permissionName: string): boolean {
    return abp.auth.isGranted(permissionName)
}
