import { FloatButton } from 'antd';
import { Fragment } from 'react';
import AppConsts from '../../lib/appconst';
import { TransportationIcon } from '../LmnIcons/LmnIcons';
import './index.css';

export function HelpMessageDev() {
  const isLocal = window.location.hostname.includes('localhost');
  if (isLocal) {
    return (
      <Fragment>
        <FloatButton
          icon={<TransportationIcon />}
          onClick={() =>
            (window.location.href = `http://${
              window.location.host
            }/?a=${decodeURIComponent(AppConsts.tokenExample)}`)
          }
        />
      </Fragment>
    );
  }

  return null;
}
