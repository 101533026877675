/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AirportsResponseDto } from '../models/AirportsResponseDto';
import type { DestinationsResponseDto } from '../models/DestinationsResponseDto';
import type { SearchLocationInputDto } from '../models/SearchLocationInputDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LocationServiceService {

    /**
     * @returns DestinationsResponseDto Success
     * @throws ApiError
     */
    public static postApiServicesAppLocationServiceGetDestinations({
        requestBody,
    }: {
        requestBody?: SearchLocationInputDto,
    }): CancelablePromise<DestinationsResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/LocationService/GetDestinations',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns AirportsResponseDto Success
     * @throws ApiError
     */
    public static postApiServicesAppLocationServiceGetAirports({
        requestBody,
    }: {
        requestBody?: SearchLocationInputDto,
    }): CancelablePromise<AirportsResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/LocationService/GetAirports',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
