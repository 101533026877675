import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const ConsentWrapper = styled.div`
  position: fixed;
  z-index: 99999998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  font-family: open sans;
  font-size: 12px;
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(8, 8, 8, 0.3);
    z-index: 1;
    pointer-events: auto;
  }
`;

export const ConsentBanner = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
`;

export const ConsentContainer = styled.div`
  width: 100%;
`;

export const ContentWrapper = styled.div`
  position: relative;
  background-color: #fff;
  z-index: 1;
  overflow: hidden;
  transition: transform 0.4s ease;
  -webkit-font-smoothing: antialiased;
  padding: 24px;
  border-radius: 0 0;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: auto;
`;

export const Title = styled.span`
  display: block;
  font-weight: 700;
  margin-bottom: 12px;
  font-size: 24px;
  padding: 0 32px;
  color: #3c3c3c;
  line-height: 36px;
  margin-right: 22%;
`;

export const Text = styled.div`
  padding: 0 32px;

  a {
    cursor: pointer;
    color: #8c8c92;
    text-decoration: underline;
  }
  button {
    color: #8c8c92;
    text-decoration: underline;
    font-size: inherit;
    font-family: inherit;
    border: none;
    background: 0 0;
    padding: 0;
    line-height: 16px;
    cursor: pointer;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  color: #8c8c92;
  overflow-y: auto;
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, black 16px);
  -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, black 16px);
  padding-bottom: 16px;
`;

export const Button = styled.div`
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #000000;
  cursor: pointer;
  width: fit-content;

  @media (max-width: 768px) {
    width: auto;
    text-align: center;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 32px;

  @media (max-width: 768px) {
    justify-content: center;
    flex-direction: column-reverse;
    gap: 8px;
  }
`;

export const ConsentButtons = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;
export const CustomButtons = styled.div`
  margin-right: 16px;
`;

export const ButtonPrimary = styled(Button)<{
  $mainColor: string;
  $textColor: string;
  $size: 'fit' | 'large';
}>`
  background-color: ${({ $mainColor }) => $mainColor || undefined};
  color: ${({ $textColor }) => $textColor || undefined};
  padding: ${({ $size }) =>
    $size === 'fit' ? '10px' : $size === 'large' ? '10px 100px' : '10px'};
  border: 1px solid ${({ $textColor }) => $textColor || '#000000'};
`;

export const ButtonSecondary = styled(Button)`
  color: black;
  background-color: transparent;
  padding: 10px 16px;
`;

export const DenyButton = styled(ButtonSecondary)`
  position: absolute;
  top: 0;
  right: 32px;
`;

export const ManageCookiesBanner = styled.div`
  position: absolute;
  z-index: 2;
  background-color: #00000042;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
`;

export const ManageCookiesContainer = styled.div`
  width: 900px;
  max-height: 80vh;
  background: white;
  display: flex;
  flex-direction: column;
  margin: 15px;
  padding: 24px;
  border-radius: 10px;
  overflow-y: auto;
`;

export const ModalHeaderButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const BackButton = styled(Button)`
  padding: 6px;
  font-size: 20px;
  border: 1px solid rgba(0, 0, 0, 0.25);
`;
export const FullDocumentButton = styled.a`
  background-color: rgba(0, 0, 0, 0.07);
  border: 1px solid rgba(0, 0, 0, 0.07);
  padding: 6px 12px;
`;
export const ModalContent = styled.div`
  margin: 24px auto;
`;
export const CookiesSection = styled.div``;
export const AllCookiesButtons = styled.div`
  display: flex;
  justify-content: end;
  gap: 8px;
  margin-top: 10px;

  @media (max-width: 768px) {
    justify-content: center;
    flex-flow: column-reverse;
    padding: 0 32px;
  }
`;
export const RejectIcon = styled(CloseOutlined)`
  color: 'black';
  margin-right: 5px;
`;
export const AcceptIcon = styled(CheckOutlined)<{ $color: string }>`
  color: ${({ $color }) => $color};
  margin-right: 5px;
`;
export const CookieTypeSelector = styled.div`
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;
export const CookieTypeTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
`;
export const InfoAndSwitch = styled.div`
  display: inline-flex;
  gap: 12px;
`;
export const ShowDescription = styled.div`
  cursor: pointer;
  line-height: 1.5em;
  font-size: 12px;
  font-weight: 300;
`;
