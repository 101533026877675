import Icon, {
  CustomIconComponentProps,
} from '@ant-design/icons/lib/components/Icon';
import { ReactComponent as Adult } from './icons/adult.svg';
import { ReactComponent as AllFilters } from './icons/all_filters.svg';
import { ReactComponent as AllInclusive } from './icons/allinclusive.svg';
import { ReactComponent as AnyBoard } from './icons/anyboard.svg';
import { ReactComponent as ArrowDown } from './icons/arrow_small.svg';
import { ReactComponent as CarryOn } from './icons/baggage_carryon.svg';
import { ReactComponent as Baggage } from './icons/baggage_checked.svg';
import { ReactComponent as Bed } from './icons/bed.svg';
import { ReactComponent as Breakfast } from './icons/breakfast.svg';
import { ReactComponent as Building } from './icons/building.svg';
import { ReactComponent as Bus } from './icons/bus.svg';
import { ReactComponent as BusLine } from './icons/busline.svg';
import { ReactComponent as Calendar } from './icons/calendar.svg';
import { ReactComponent as ChangeAirport } from './icons/change_airport.svg';
import { ReactComponent as Child } from './icons/child.svg';
import { ReactComponent as Copy } from './icons/copy.svg';
import { ReactComponent as Dot } from './icons/dot.svg';
import { ReactComponent as FreeCancellation } from './icons/freeCancellation.svg';
import { ReactComponent as FullBoard } from './icons/fullboard.svg';
import { ReactComponent as GlobeMap } from './icons/globe-map.svg';
import { ReactComponent as HalfBoard } from './icons/halfboard.svg';
import { ReactComponent as HandLuggage } from './icons/hand_luggage.svg';
import { ReactComponent as Hotel } from './icons/hotel.svg';
import { ReactComponent as HotelBed } from './icons/hotelBed.svg';
import { ReactComponent as Infant } from './icons/infant.svg';
import { ReactComponent as Info } from './icons/info.svg';
import { ReactComponent as LastminuteDeal } from './icons/lastminute_deals.svg';
import { ReactComponent as LastminuteDealWhite } from './icons/lastminute_deals_white.svg';
import { ReactComponent as Luggage } from './icons/luggage.svg';
import { ReactComponent as Marker } from './icons/marker.svg';
import { ReactComponent as Moon } from './icons/moon.svg';
import { ReactComponent as MultipleAirlines } from './icons/multiple_airlines.svg';
import { ReactComponent as NoAirlineLogo } from './icons/no_airline_logo.svg';
import { ReactComponent as NoCarryOn } from './icons/no_baggage_carryon.svg';
import { ReactComponent as NoBaggage } from './icons/no_baggage_checked.svg';
import { ReactComponent as NoTrainCompanyLogo } from './icons/no_traincompany_logo.svg';
import { ReactComponent as NoMeal } from './icons/nomeal.svg';
import { ReactComponent as Passport } from './icons/passport.svg';
import { ReactComponent as Phone } from './icons/phone.svg';
import { ReactComponent as Pin } from './icons/pin.svg';
import { ReactComponent as Plane } from './icons/plane.svg';
import { ReactComponent as PlaneNE } from './icons/plane_ne.svg';
import { ReactComponent as Priority } from './icons/priority.svg';
import { ReactComponent as Search } from './icons/search.svg';
import { ReactComponent as SeatNotAvailable } from './icons/seat_not_available.svg';
import { ReactComponent as Shield } from './icons/shield.svg';
import { ReactComponent as Sort } from './icons/sort.svg';
import { ReactComponent as TopSecretWhite } from './icons/top_secret.svg';
import { ReactComponent as TopSecretWhiteMask } from './icons/top_secret_mask.svg';
import { ReactComponent as TopSecretWhiteMask2 } from './icons/top_secret_mask_2.svg';
import { ReactComponent as TrainLine } from './icons/trainline.svg';
import { ReactComponent as Transportation } from './icons/transportation.svg';
import { ReactComponent as ViewList } from './icons/view_list.svg';
import { ReactComponent as ViewMap } from './icons/view_map.svg';

import type { CSSProperties } from 'styled-components';
import { styled } from 'styled-components';

export const ArrowDownIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => <Icon component={ArrowDown} {...props} style={{ fontSize: props.size }} />;

export const HotelIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Hotel}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const PriorityIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Priority}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const PlaneIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Plane}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const CityIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Building}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const NoMealIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={NoMeal}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const HalfBoardIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={HalfBoard}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const FullBoardIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={FullBoard}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const AnyBoardIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={AnyBoard}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const BreakFastIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Breakfast}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const AllInclusiveIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={AllInclusive}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const LastminuteDealIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={LastminuteDeal}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const LastminuteDealWhiteIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={LastminuteDealWhite}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const TopSecretWhiteIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={TopSecretWhite}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const TopSecretWhiteMaskIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={TopSecretWhiteMask}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const TopSecretWhiteMask2Icon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={TopSecretWhiteMask2}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const InfoIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Info}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const MultipleAirlinesIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={MultipleAirlines}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const PlaneNEIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={PlaneNE}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const BaggageCheckedIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Baggage}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const CarryOnIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={CarryOn}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const NoBaggageCheckedIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={NoBaggage}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const NoCarryOnIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={NoCarryOn}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const BedIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Bed}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const HotelBedIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={HotelBed}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const MoonIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    style?: CSSProperties;
    size?: number;
  }
) => <Icon component={Moon} {...props} style={{ ...props.style }} />;
export const MarkerIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Marker}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const FreeCancellationIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={FreeCancellation}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const CalendarIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Calendar}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const ShieldIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Shield}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const PassportIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Passport}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const AdultIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Adult}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const ChildIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Child}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const InfantIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Infant}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const CopyIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Copy}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const DotIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Dot}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const PhoneIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Phone}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const SearchIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Search}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const TransportationIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Transportation}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const NoAirlineLogoIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={NoAirlineLogo}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const NoTrainCompanyLogoIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={NoTrainCompanyLogo}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const BusIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Bus}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const BusLineIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={BusLine}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const TrainLineIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={TrainLine}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const SeatNotAvailableIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={SeatNotAvailable}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const ChangeAirportIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={ChangeAirport}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const LuggageIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Luggage}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const HandLuggageIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={HandLuggage}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const SortIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Sort}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const AllFiltersIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={AllFilters}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const ViewMapIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={ViewMap}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const ViewListIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={ViewList}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const PinIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Pin}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const GlobeMapIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={GlobeMap}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const IconWrapper = styled.div`
  padding: 10px 16px 10px 16px;
  background-color: white;
  border-radius: 10px;
  font-size: 20px;
`;
