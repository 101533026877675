/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import { message } from 'antd';
import axios from 'axios';

import { L } from '../lib/abpUtility'; // TODO Remove L from ts files
import AppConsts from '../lib/appconst';

const qs = require('qs');

declare var abp: any;

const http = axios.create({
  baseURL: AppConsts.remoteServiceBaseUrlWelfare,
  timeout: 3000000,
  paramsSerializer(params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});

http.interceptors.request.use(
  function (config) {
    if (abp.auth.getToken()) {
      config.headers.Authorization = `Bearer ${abp.auth.getToken()}`;
    }

    config.headers['.AspNetCore.Culture'] = abp.utils.getCookieValue(
      'Abp.Localization.CultureName'
    );
    config.headers['Abp-TenantId'] = abp.multiTenancy.getTenantIdCookie();

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      !error.request.responseURL.includes(
        'ShoppingTransportService/RetrieveProposals'
      )
    ) {
      message.config({ maxCount: 1 });
      if (
        !!error.response &&
        !!error.response.data.error &&
        !!error.response.data.error.message &&
        error.response.data.error.details
      ) {
        console.error(error.response.data.error.details);
        message.error(L(error.response.data.error.details), 10);
      } else if (
        !!error.response &&
        !!error.response.data.error &&
        !!error.response.data.error.message
      ) {
        console.error(error.response.data.error.message);
        message.error(L(error.response.data.error.message), 10);
      } else if (!error.response) {
        if (
          !error.request.responseURL.includes('Reservation/CreateReservation')
        ) {
          console.log(L('UnknownError'));
          message.warning(L('SomethingGotWrong'));
        }
      }

      setTimeout(() => {}, 1000);
    }
    return Promise.reject(error);
  }
);

export default http;
