import http from './httpService';

class AbpUserConfigurationService {
  public async getAll({language}) {
    const result = await http.get('/AbpUserConfiguration/GetAll', {
      params: { "culture": language, "ui-culture": language },
    });
    return result;
  }
}

export default new AbpUserConfigurationService();