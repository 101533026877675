import { makeAutoObservable, runInAction } from 'mobx';
import {
  AirportsResponseDto,
  DestinationsResponseDto,
  LocationServiceService,
  SearchItem,
  SearchLocationInputDto,
} from '../../services/codegen-romulo';

export default class ShoppingSelectionStore {
  departureAirportsSearch?: SearchItem[];
  arrivalAirportsSearch?: SearchItem[];

  destinationSearch?: SearchItem[];

  constructor() {
    makeAutoObservable(this);
  }

  getAirports = async (
    requestBody: SearchLocationInputDto,
    type: 'F' | 'T'
  ): Promise<AirportsResponseDto> => {
    const response =
      await LocationServiceService.postApiServicesAppLocationServiceGetAirports(
        {
          requestBody,
        }
      );

    runInAction(() => {
      switch (type) {
        case 'F':
          this.departureAirportsSearch = response.items;
          break;
        case 'T':
          this.arrivalAirportsSearch = response.items;
          break;
      }
    });

    return response;
  };

  getDestinationPlace = async (
    requestBody: SearchLocationInputDto
  ): Promise<DestinationsResponseDto> => {
    const response =
      await LocationServiceService.postApiServicesAppLocationServiceGetDestinations(
        {
          requestBody,
        }
      );

    runInAction(() => {
      this.destinationSearch = response.items;
    });

    return response;
  };
}
