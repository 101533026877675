import { useFlag } from '@unleash/proxy-client-react';
import { getSearchParam } from '../utils/getSearchParam';

const useGetFlag = (featureName: string) => {
  const flagValue = useFlag(featureName);
  const queryParam = getSearchParam(featureName);

  if (queryParam) {
    return queryParam === 'true' ? true : false;
  } else {
    return flagValue;
  }
};

export default useGetFlag;
