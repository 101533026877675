import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { useTranslate } from '@tolgee/react';
import useGetFlag from '../../hooks/useGetFlag';
import Exception from '../../scenes/Exception';
import TravelLayout from '../Layout/TravelLayout/TravelLayout';
import MaintenanceModeScreen from '../MaintenanceModeScreen/MaintenanceModeScreen';
import ProtectedRoute from './protectedRoute';
import travelRouter from './routers/travelRouter';
const Router = () => {
  const { t } = useTranslate();
  const maintenanceMode = useGetFlag('travel.general.maintenance');

  if (maintenanceMode) {
    return (
      <MaintenanceModeScreen
        title={t('maintenance.title')}
        subtitle={t('maintenance.description')}
        ctaLabel={t('maintenance.cta')}
      />
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        {travelRouter.map((item, key) => (
          <Route
            key={key}
            path={item.path}
            element={
              <ProtectedRoute>
                <TravelLayout path={item.path} />
              </ProtectedRoute>
            }
          />
        ))}
        <Route path="/exception" element={<Exception />} />
        <Route
          path="*"
          element={
            <Navigate
              to={{
                pathname: '/exception',
                search: 'type=404',
              }}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
