import { AuthenticatedDto, AuthenticatorInputDto } from '../codegen-welfare';
import http from '../httpService';
import { AuthenticationModel } from './dto/authenticationModel';
import { AuthenticationResultModel } from './dto/authenticationResultModel';

class TokenAuthService {
  public async authenticate(
    authenticationInput: AuthenticationModel
  ): Promise<AuthenticationResultModel> {
    const result = await http.post(
      'api/TokenAuth/Authenticate',
      authenticationInput
    );
    return result.data.result;
  }

  public async authenticateExternal(
    authenticationInput: AuthenticatorInputDto
  ): Promise<AuthenticatedDto> {
    const result = await http.post(
      'api/TokenAuth/AuthenticateExternal',
      authenticationInput
    );
    return result.data.result;
  }
}

export default new TokenAuthService();
