import type { IConfig } from '@unleash/proxy-client-react';
import AppConsts from '../../lib/appconst';

const unleashConfig: IConfig = {
  url: AppConsts.unleashApiUrl,
  clientKey: AppConsts.unleashClientKey,
  refreshInterval: 60,
  appName: 'default',
  environment: AppConsts.unleashEnv,
};

export { unleashConfig };
