/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InitPaymentResponseDto } from '../models/InitPaymentResponseDto';
import type { InputNoosaInitPaymentDto } from '../models/InputNoosaInitPaymentDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NoosaService {

    /**
     * @returns InitPaymentResponseDto Success
     * @throws ApiError
     */
    public static postApiServicesAppNoosaInitPayment({
        requestBody,
    }: {
        requestBody?: InputNoosaInitPaymentDto,
    }): CancelablePromise<InitPaymentResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/Noosa/InitPayment',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
