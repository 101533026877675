import { useEffect } from 'react';

export default function useExternalScripts({ url, attributes }) {
  const attributesKeys = Object.keys(attributes);

  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');

    script.setAttribute('src', url);
    attributesKeys.map((att) => {
      script.setAttribute(att, attributes[att]);
    });

    head.appendChild(script);

    return () => {
      head.removeChild(script);
    };
  }, [url]);
}
