import { action, observable, makeObservable } from 'mobx'

import IsTenantAvaibleOutput from '../services/account/dto/isTenantAvailableOutput'
import accountService from '../services/account/accountService'

class AccountStore {
    tenant: IsTenantAvaibleOutput = new IsTenantAvaibleOutput()

    public isTenantAvailable = async (tenancyName: string) => {
        this.tenant = await accountService.isTenantAvailable({ tenancyName })
    }

    constructor() {
        makeObservable(this, {
            tenant: observable,
            isTenantAvailable: action,
        })
    }
}

export default AccountStore
