/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReservationInputBaseDto } from '../models/ReservationInputBaseDto';
import type { StatusMasterDto } from '../models/StatusMasterDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReservationService {

    /**
     * @returns number Success
     * @throws ApiError
     */
    public static getApiServicesAppReservationGetReservationStatusByCartId({
        cartId,
    }: {
        cartId?: string,
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/services/app/Reservation/GetReservationStatusByCartId',
            query: {
                'cartId': cartId,
            },
        });
    }

    /**
     * @returns StatusMasterDto Success
     * @throws ApiError
     */
    public static postApiServicesAppReservationCreateReservation({
        requestBody,
    }: {
        requestBody?: ReservationInputBaseDto,
    }): CancelablePromise<StatusMasterDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/Reservation/CreateReservation',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
