import { Card } from 'antd';
import { styled } from 'styled-components';

const StyledCardBorderColor = styled(Card)<{
  $borderColor?: string;
  $borderRadius?: string;
}>`
  border-color: ${(props) =>
    props.$borderColor ? props.$borderColor : '#f0f0f0'};
  border-radius: ${(props) =>
    props.$borderRadius ? props.$borderRadius : '16px'};
`;

const StyledCardLarge = styled(StyledCardBorderColor)`
  width: 100%;
  .ant-card-body {
    padding: 16px;
  }
`;

const StyledCardSmall = styled(StyledCardBorderColor)`
  .ant-card-body {
    padding: 8px 10px;
  }
`;

const StyledCardExtraSmall = styled(StyledCardBorderColor)`
  .ant-card-body {
    padding: 0;
  }
`;

const StyledCardNoStyle = styled(StyledCardBorderColor)`
  border: none;
  background: transparent;

  .ant-card-body {
    padding: 25px;
  }
`;

export default {
  StyledCardLarge,
  StyledCardSmall,
  StyledCardExtraSmall,
  StyledCardNoStyle,
};
