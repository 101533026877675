import { action, observable, makeObservable } from 'mobx';

import { GetCurrentLoginInformations } from '../services/session/dto/getCurrentLoginInformations';
import sessionService from '../services/session/sessionService';

class SessionStore {
  currentLogin: GetCurrentLoginInformations = new GetCurrentLoginInformations();

  constructor() {
    makeObservable(this, {
      currentLogin: observable,
      getCurrentLoginInformations: action,
      userName: action
    });
  }

  async getCurrentLoginInformations():Promise<void> {
    const result = await sessionService.getCurrentLoginInformations();
    this.currentLogin = result;
  }

  userName():string{
    return this.currentLogin.user.userName;
  }
}

export default SessionStore;