import { makeAutoObservable, runInAction } from 'mobx';
import {
  InitPaymentResponseDto,
  InputNoosaInitPaymentDto,
  NoosaService,
  ReservationInputBaseDto,
  ReservationService,
  StatusMasterDto,
} from '../../services/codegen-welfare';

export default class ReservationStore {
  reservationStatus?: 'InProcess' | 'Complete' | 'Error';

  noosaPaymentInfo?: InitPaymentResponseDto;

  constructor() {
    makeAutoObservable(this);
  }

  createReservation = async (
    requestBody: ReservationInputBaseDto
  ): Promise<StatusMasterDto> => {
    const response =
      await ReservationService.postApiServicesAppReservationCreateReservation({
        requestBody,
      });
    return response;
  };

  getReservationStatus = async (
    cartId: string
  ): Promise<'InProcess' | 'Complete' | 'Error' | 'Pending'> => {
    return ReservationService.getApiServicesAppReservationGetReservationStatusByCartId(
      {
        cartId,
      }
    ).then((response) => {
      switch (response) {
        case 1:
          return 'InProcess';
        case 2:
          return 'Complete';
        case 3:
          return 'Error';
        case 4:
        default:
          return 'Pending';
      }
    });
  };

  initNoosaPayment = async (
    requestBody: InputNoosaInitPaymentDto
  ): Promise<InitPaymentResponseDto> => {
    const response = await NoosaService.postApiServicesAppNoosaInitPayment({
      requestBody,
    });
    runInAction(() => {
      this.noosaPaymentInfo = response;
    });
    return response;
  };
}
